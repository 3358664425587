<template>
  <div class="box">
    <el-form :model="info" label-position="left" :rules="rules" ref="ruleForm" label-width="80px" size="small">
      <el-form-item label="选择门店" prop="shop_id" v-if="shopList && shopList.length>0 && !info.pid">
        <el-select v-model="info.store_id" placeholder="请选择门店" size="small" style="width: 100%">
          <el-option
              v-for="item in shopList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="分类名称" prop="title" v-if="info.pid>0">
        <el-input v-model="info.title" placeholder="请输入分类"></el-input>
      </el-form-item>
      <el-form-item label="车型名称" prop="title" v-else>{{info.pid}}
        <el-input v-model="info.title" placeholder="请输入车型"></el-input>
      </el-form-item>
      <el-form-item label="自定义字段" prop="fields" v-if="!info.pid">
        <el-row>
          <el-col :span="12" class="mg-rt-20">
            <el-button type="warning" @click="addBtn('')" size="mini">添加字段</el-button>
            <div class="it-box" v-if="info.fields && info.fields.length>0">
              <div class="item mg-tp-10" style="display: flex; justify-content: space-between;" v-for="(item,index) in info.fields" :key="index">
                <el-input v-model="item.title" placeholder="请输入车型" class="mg-rt-20"></el-input>
                <el-button type="danger" icon="el-icon-delete" circle @click="delBtn(index)"></el-button>
              </div>
            </div>
          </el-col>
          <el-col :span="11">
            <div style="font-size: 12px; margin-bottom: 10px;">快速选择（点击标签自动创建）</div>
            <el-tag v-for="(item,index) in tagList" :key="index" class="mg-rt-5 mg-bt-5" style="cursor: pointer" @click="addBtn(item)">{{item}}</el-tag>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

export default {
  data() {
    return {
      info: {
        id: '',
        store_id: '',
        title: '',
        fields: []
      },
      rules: {
        title: [
          {required: true, message: '请输入名称', trigger: 'blur'}
        ],
      },
      tagList: ['验收标准制定','同行产品调查','同行产品话术收集','产品详细思维导图','蓝电话术整理','蓝电收藏话术完成','产品图片拍摄','产品视频（包含产品基本功能，安装施工，问题解答）','价格表入表','负责人']
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  props: {
    shopList: Array,
    menuInfo: Object
  },
  watch: {
    'menuInfo': {
      handler (newValue, oldValue) {
        if(newValue) {
          this.info = newValue
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    addBtn(title) {
      var max = 0
      if(this.info.fields) {
        this.info.fields.forEach(item => {
          if(item.key>max) {
            max = item.key
          }
        })
        this.info.fields.push({key:max+1,title:title})
      } else {
        this.info.fields = [{key:1,title:title}]
      }
    },
    delBtn(idx) {
      this.info.fields.splice(idx,1)
    },
    submitForm(formName) {
      var _this = this
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          var param = JSON.parse(JSON.stringify(this.info))
          this.$api.operate.carModelAdd(param,function (res) {
            if(res.errcode == 0) {
              _this.success(res.errmsg)
              _this.$emit('callFunc');
            } else {
              _this.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
</style>
